import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-slides-routes',
  templateUrl: './slides-routes.component.html',
  styleUrls: ['./slides-routes.component.scss'],
})
export class SlidesRoutesComponent implements OnInit {

  @Input() point: any;
  @Input() pointRoutes: any;

  @ViewChild('slider') slider;

  slideOpts: any = {
    freeMode: true,
    slidesPerView: 1.5,
    spaceBetween: 20
  };
  
  constructor(private mapService: MapService, private router: Router) { }

  ngOnInit() {
    this.pointRoutes.forEach(element => {
      this.createFormattedTitle(element);
      if (element.medias.length) {
        element.thumbnail = element.medias[0].url;
      }
      if (element.trace) {
        switch (element.trace) {
          case '1':
            element.locomotion = 'Marche'
            break;
          case '2':
            element.locomotion = 'Vélo'
            break;
          case '3':
            element.locomotion = 'Voiture'
            break;
        }
      }
    });
  }

  createFormattedTitle(route) {
    route.title_formatted = route.title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
  }

  onNavigate(id, title) {
    this.mapService.showPoint('close');
    this.router.navigate(['/route', id, title]);
  }

}