import { ChangeDetectorRef, Component, NgZone, HostListener } from '@angular/core';
import { CommonService } from './services/common.service';
import { NavigationStart, Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { LoadingController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { environment } from '../environments/environment';
const { Geolocation } = Plugins;
const { Device } = Plugins;
const { Storage } = Plugins;
const { Network } = Plugins;
const { App } = Plugins;
const { Browser } = Plugins;
import { StatsService } from 'src/app/services/stats.service';
import { ApiService } from './services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from './services/map.service';
import { CachingService } from './services/caching.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  loading: any;

  initDone : boolean = false;
  menu: boolean = true;

  ipAdrress: string;
  isp: string;
  lat: string;
  lon: string;
  typeGeoloc: string;

  toasterDestinations: boolean = false;
  toasterShare: boolean = false;

  destinations: any;
  shareData: any;

  modalPWA: boolean = false;
  modalPWAContent: string;

  modalPoint: boolean = false;
  point: any;

  networkStatus: any;
  networkOn: any = true;

  isMapOnDesktop: boolean = false;

  constructor(private zone: NgZone, private oneSignal: OneSignal, private loadingController: LoadingController, private cachingService: CachingService, private changeDetectorRef: ChangeDetectorRef, private mapService: MapService, private platform: Platform, private translateService: TranslateService, private http: HttpClient, private statsService: StatsService, private commonService: CommonService, private router: Router, private apiService: ApiService) {
    this.cachingService.initStorage();
    this.initializeApp();
    platform.ready().then(() => {   
      this.initDeeplinks();   
      if (platform.is('desktop')) {
        let OneSignal = window['OneSignal'] || [];
        OneSignal.push(function () {
          OneSignal.init({
            appId: environment.oneSignalID,
            safari_web_id: environment.oneSignalSafariID,
            notifyButton: {
              enable: true,
            },
          });
        });
      } else {
        this.oneSignal.startInit(environment.oneSignalID, environment.googleProjectNumber);
        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
        this.oneSignal.handleNotificationOpened().subscribe((data) => {
          let launchURL = data.notification.payload.launchURL;
          if (launchURL && launchURL.startsWith('link://' + environment.baseUrl)) {
            let url = '/' + launchURL.replace('link://' + environment.baseUrl, '');
            this.router.navigateByUrl(url);
          }
        });    
        this.oneSignal.endInit();
        this.addUserToken();
      }
      if (!platform.is('hybrid')) {
        // this.initModalPWA();
      }
    });
    Network.addListener('networkStatusChange', (status) => {
      if (!status.connected) {
        this.networkOn = false;
      } else {
        this.networkOn = true;
      }
    });
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          if (event.url.includes('parcours')) {
            this.menu = false;
          } else {
            this.menu = true;
          }
          if (event.url == '/map' && window.innerWidth > 800) {
            this.isMapOnDesktop = true;
          } else {
            this.isMapOnDesktop = false;
          }
          this.addStat(event.url);
        }
      }
    );
  }

  async initDeeplinks() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const slug = data.url.split(environment.deeplinkSplit).pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-loader'
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async addUserToken() {
    const info = await Device.getInfo();
    const language = await Device.getLanguageCode();
    this.oneSignal.getIds().then(identity => {
      this.statsService.addUser(info, language, identity.pushToken).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  async initializeApp() {
    await this.presentLoading();
    let lang = this.translateService.getBrowserLang();
    if (lang.startsWith('fr')) lang = 'fr_FR';
    if (lang.startsWith('en')) lang = 'en_GB';
    if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
    this.networkStatus = await Network.getStatus();
    this.getConfig(lang);
    this.setTranslations();
    (this.networkStatus.connected)? this.tryGetIPOne() : this.initDone = true;
    (this.networkStatus.connected)? this.networkOn = true : this.networkOn = false;
    await this.dismissLoading()
    this.commonService.getDestinations().subscribe((data) => {
      if (data == 'close') {
        this.toasterDestinations = false;
      } else {
        this.toasterShare = false;
        this.toasterDestinations = true;
        this.destinations = data;  
      }
    });
    this.commonService.getShare().subscribe((data) => {
      if (data == 'close') {
        this.toasterShare = false;
      } else {
        this.toasterDestinations = false;
        this.toasterShare = true;
        this.shareData = data;  
      }
    });
    this.mapService.getPoint().subscribe((data) => {
      if (data == 'close') {
        this.modalPoint = false;
        this.changeDetectorRef.detectChanges();
      } else {
        this.modalPoint = true;
        this.point = data;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getConfig(lang) {
    if (this.networkStatus.connected) {
      this.apiService.getConfig({lang: lang}).subscribe(
        data => {
          let config = data.data[0];
          Storage.set({ key: 'navColor', value: config.color_nav });
          document.documentElement.style.setProperty('--primary-color', config.colorp);
          document.documentElement.style.setProperty('--secondary-color', config.colora);
          document.documentElement.style.setProperty('--primary-text-color', config.color_txtp);
          document.documentElement.style.setProperty('--secondary-text-color', config.color_txta);
          document.documentElement.style.setProperty('--link-color', config.color_link);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      from(this.cachingService.getCachedRequest(`${environment.baseApiUrl}config/list`)).subscribe(
        data => {
          let config = data.data[0];
          Storage.set({ key: 'navColor', value: config.color_nav });
          document.documentElement.style.setProperty('--primary-color', config.colorp);
          document.documentElement.style.setProperty('--secondary-color', config.colora);
          document.documentElement.style.setProperty('--primary-text-color', config.color_txtp);
          document.documentElement.style.setProperty('--secondary-text-color', config.color_txta);
          document.documentElement.style.setProperty('--link-color', config.color_link);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  async setTranslations() {
    const language = await Device.getLanguageCode();
    let translateLang = language.value.substr(0,2);
    this.translateService.setDefaultLang(translateLang);
    this.translateService.use(translateLang);

    if (this.networkStatus.connected) {
      this.apiService.getLangElements().subscribe(
        data => {
          data.data.forEach(traduction => {
            let lang = traduction.lang.substr(0,2);
            let name = traduction.name;
            let content = traduction.content;
            let object = {[name]: content};
            this.translateService.setTranslation(lang, object, true);
          });
        },
        err => {
          console.log(err);
        }
      );
    } else {
      from(this.cachingService.getCachedRequest(`${environment.baseApiUrl}lang_element/list`)).subscribe(
        data => {
          data.data.forEach(traduction => {
            let lang = traduction.lang.substr(0,2);
            let name = traduction.name;
            let content = traduction.content;
            let object = {[name]: content};
            this.translateService.setTranslation(lang, object, true);
          });
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  tryGetIPOne() {
    this.http.get('https://api.ipgeolocation.io/ipgeo?apiKey=a7e3db6be83844958ec177bba378d909').subscribe(
      (res: any) => {
        this.typeGeoloc = 'IP';
        this.ipAdrress = res.ip;
        this.isp = res.isp;
        this.lat = res.latitude;
        this.lon = res.longitude;
        this.locateUser();
      },
      error => {
        this.tryGetIPTwo();
      }
    );
  }

  tryGetIPTwo() {
    this.http.get('https://api.ipify.org?format=json').subscribe(
      (res: any) => {
        this.ipAdrress = res.ip;
        this.http.get('http://api.ipstack.com/' + this.ipAdrress + '?access_key=273187c39d9843fe030e6ee7d130d149&format=1').subscribe(
          (res: any) => {
            this.typeGeoloc = 'IP';
            this.lat = String(res.latitude);
            this.lon = String(res.longitude);
            this.locateUser();
          },
          error => {
            this.locateUser();
          }
        );
      },
      error => {
        this.locateUser();
      }
    );
  }

  async locateUser() {
    Geolocation.getCurrentPosition({timeout: 3000, enableHighAccuracy: true}).then(
      coordinates => {
        this.typeGeoloc = 'GPS';
        this.lat = String(coordinates.coords.latitude);
        this.lon = String(coordinates.coords.longitude);
        this.initStats();
      }, 
      error => {
        this.initStats();
      }
    );
  }

  async initStats() {
    const info = await Device.getInfo();
    const language = await Device.getLanguageCode();
    this.statsService.addUser(info, language.value).subscribe(
      dataUser => {
        this.statsService.addDevice(dataUser.data.stats_users_id, this.ipAdrress, this.isp).subscribe(
          dataDevice => {
            this.setStorage(dataUser, dataDevice);
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  async setStorage(dataUser, dataDevice) {
    await Storage.set({
      key: 'stats_users_id',
      value: JSON.stringify({
        stats_users_id: dataUser.data.stats_users_id
      })
    });
    await Storage.set({
      key: 'stats_devices_id',
      value: JSON.stringify({
        stats_devices_id: dataDevice.data.stats_devices_id
      })
    });
    this.addStat(this.router.url);
  }

  async addStat(url) {
    const deviceKey = await Storage.get({ key: 'stats_devices_id' });
    const device = JSON.parse(deviceKey.value);
    if (device) {
      let stats_devices_id = device.stats_devices_id;
      let titleIndex = url.lastIndexOf('/');
      let title = url.substring(titleIndex + 1).split('?fbclid')[0];
      if (title == 'parcours') title = url.substring(0, titleIndex).split('/').pop();
      this.postStats(stats_devices_id, url, title, this.lat, this.lon);
    }
  }

  postStats(stats_devices_id, url, title, lat, lon) {
    this.statsService.addStatApp(stats_devices_id, url, title, lat, lon, this.typeGeoloc).subscribe(
      data => {
        if (!this.initDone) {
          this.initDone = true;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  initModalPWA() {
    setTimeout(() => {
      this.translateService.get('modal_pwa').subscribe((text:string)=> {
        this.modalPWAContent = text;
        this.modalPWA = true;
      });
    }, 10000);
  }

  closeModalPWA() {
    this.modalPWA = false;
  }

  @HostListener('document:click', ['$event'])
  documentClick(e) {
    let element: HTMLElement = e.target;
    if ((element.tagName == 'A') && (element.getAttribute("href"))) {
      e.preventDefault();
      this.openInAppURL(element.getAttribute("href"));
    }
  }

  async openInAppURL(url) {
    const keyColor = await Storage.get({ key: 'navColor' });
    let navColor = keyColor.value;
    if (!navColor) navColor = '#CCC';
    if (this.platform.is('ipad')) {
      await Browser.open({ url: url, presentationStyle: 'fullscreen', toolbarColor: navColor });
    } else {
      await Browser.open({ url: url, presentationStyle: 'popover', toolbarColor: navColor });
    }  
  }

}