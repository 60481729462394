import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-slides-publications',
  templateUrl: './slides-publications.component.html',
  styleUrls: ['./slides-publications.component.scss'],
})
export class SlidesPublicationsComponent implements OnInit {

  @Input() point: any;
  @Input() pointPublications: any;

  @ViewChild('slider') slider;

  slideOpts: any = {
    freeMode: true,
    slidesPerView: 1.5,
    spaceBetween: 20
  };
  
  constructor(private translateService: TranslateService, private mapService: MapService, private router: Router) { }

  ngOnInit() {
    this.pointPublications.forEach(element => {
      this.createFormattedTitle(element);
      if (element.description.length > 40) {
        element.title = element.title.substring(0, 40) + '...';
      }
      if (element.description.length > 100) {
        element.description = element.description.substring(0, 100) + '...';
      }
      let date: any = new Date(element.updated_at);
      let timestamp = Math.floor(date / 1000);
      let lang = this.translateService.getDefaultLang();
      element.time_ago = timeSince(timestamp, lang);
      if (element.medias.length) {
        element.medias.forEach(media => {
          if (!(media.mime_type.startsWith('audio')) && !element.thumbnail) {
            element.thumbnail = media.url;
          }
        });
      }
    });
  }

  createFormattedTitle(publication) {
    publication.title_formatted = publication.title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
  }

  onNavigate(id, title) {
    this.mapService.showPoint('close');
    this.router.navigate(['/publication', id, title]);
  }

}

function timeSince(timestamp, lang) {
  let now: any = new Date();
  let nowTimestamp = Math.floor(now / 1000);
  let seconds = nowTimestamp - timestamp;

  var interval = seconds / 31536000;

  if (lang == 'en') {
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " year ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " month ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " day ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " hour ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " minute ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  } else {
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " an";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " ans";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " mois";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " mois";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " jour";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " jours";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " heure";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " heures";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " minute";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " minutes";
    }
    return "Il y a " + Math.floor(seconds) + " secondes";
  }

}