import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
import * as Bowser from "bowser";

const { Device } = Plugins;
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  deviceInfos: any;

  constructor(private http: HttpClient) { }

  addUser(infos: any, language: any, pushToken?: any) {
    this.deviceInfos = infos;
    this.deviceInfos.language = language;
    const form: FormData = new FormData();
    if (this.deviceInfos.uuid) form.set('uuid', this.deviceInfos.uuid);
    if (pushToken) form.set('push_token', pushToken);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}stats/add_statuser`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addDevice(stats_users_id, ip: string, fai?: string) {

    const form: FormData = new FormData();
    form.set('stats_users_id', stats_users_id);
    form.set('ipv4', ip || null);

    if (fai) form.set('fai', fai);
    if (this.deviceInfos.platform) form.set('platform', this.deviceInfos.platform);
    if (this.deviceInfos.operatingSystem) form.set('os', this.deviceInfos.operatingSystem);
    if (this.deviceInfos.osVersion) form.set('os_version', this.deviceInfos.osVersion);

    let lang = this.deviceInfos.language;
    if (lang.startsWith('fr')) lang = 'fr_FR';
    if (lang.startsWith('en')) lang = 'en_GB';
    if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
    if (lang) {
      form.set('lang', lang);
    } else {
      form.set('lang', 'fr_FR');
    }

    if (this.deviceInfos.platform == 'web') {
      const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
      form.set('browser', browser.name);
      form.set('browser_version', browser.version);
    } else {
      form.set('browser', this.deviceInfos.platform);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/add_statdevice`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addStatApp(stats_devices_id, url?: string, title?: string, lat?: string, lon?: string, type_geoloc?: string) {

    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('app_vue', url);

    if (title) form.set('title', title);
    (lat) ? form.set('lat', lat) : form.set('lat', '48.856614');
    (lon) ? form.set('lon', lon) : form.set('lon', '2.3522219');
    if (type_geoloc) form.set('type_geoloc', type_geoloc);

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/add_statapp`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addStatAction(stats_devices_id, type: string, options?: {point_id?: string, route_id?: string, variation_id?: string, article_id?: string, media_id?: string, video_code_id?: string}) {
   
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('type', type);

    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
      if (options.route_id) form.set('route_id', options.route_id);
      if (options.variation_id) form.set('variation_id', options.variation_id);
      if (options.article_id) form.set('article_id', options.article_id);
      if (options.media_id) form.set('media_id', options.media_id);
      if (options.video_code_id) form.set('video_code_id', options.video_code_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}stats/add_stataction`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }
}
