import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() { }

  private point = new Subject<any>();
  private newPoint = new Subject<any>();
  private newPointFromMap = new Subject<any>();
  private newBounds = new Subject<any>();

  showPoint(data: any) {
    this.point.next(data);
  }

  getPoint(): Subject<any> {
    return this.point;
  }

  showNewPoint(data: any) {
    this.newPoint.next(data);
  }

  getNewPoint(): Subject<any> {
    return this.newPoint;
  }

  showNewPointFromMap(data: any) {
    this.newPointFromMap.next(data);
  }

  getNewPointFromMap(): Subject<any> {
    return this.newPointFromMap;
  }

  showNewBounds(data: any) {
    this.newBounds.next(data);
  }

  getNewBounds(): Subject<any> {
    return this.newBounds;
  }
  
}
