import { Component, OnInit, NgZone } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { ApiService } from 'src/app/services/api.service';
import { CallbackID, Plugins } from '@capacitor/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
const { Storage } = Plugins;
const { Network } = Plugins;
import { MetaService } from '../../services/meta.service';
import { MapService } from '../../services/map.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { StatsService } from '../../services/stats.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-desktop',
  templateUrl: './map-desktop.component.html',
  styleUrls: ['./map-desktop.component.scss'],
})
export class MapDesktopComponent implements OnInit {

  loading: any;

  map: mapboxgl.Map;
  geocoder: MapboxGeocoder;
  bounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
  boundsUserNearestPoint: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();

  allMarkers: any = [];
  markersMap: any = [];
  currentMarkers: any = [];
  hiddenMarkers: any = [];
  allCategories: any = [];

  deviceID: string;

  watchCoordinate: any;
  watchId: CallbackID;

  networkStatus: any;

  mapboxPK: string = environment.mapboxPK;

  constructor(private statsService: StatsService, private mapService: MapService, private metaService: MetaService, private router: Router, private zone: NgZone, private translateService: TranslateService, private apiService: ApiService, private loadingController: LoadingController) {
    this.metaService.setTitle('Tous les points géolocalisés de ', true);
    this.metaService.createCanonicalURL();
    this.metaService.updateTags({
      description: '',
      descriptionFromApi: true,
      articlePublishedTime: '',
      articleModifiedTime: '',
      ogLang: true,
      ogUrl: true,
      ogTitle: 'Tous les points géolocalisés de ',
      ogTitleFromApi: true,
      ogDescription: '',
      ogDescriptionFromApi: true,
      ogType: 'website'
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-loader'
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async getDeviceId() {
    const deviceKey = await Storage.get({ key: 'stats_devices_id' });
    const device = JSON.parse(deviceKey.value);
    this.deviceID = String(device.stats_devices_id);
  }

  async ngOnInit() {
    await this.presentLoading();
    await this.getDeviceId();
    this.networkStatus = await Network.getStatus();
    if (!this.networkStatus.connected) {
      await this.dismissLoading();
      return;
    }
    this.getCategories();
    this.allMarkers = [];
    this.apiService.getAllPointsAndVariations(this.deviceID).subscribe(
      data => {
        let pointsFiltered = data.data.points.filter(point => point.publication_count || point.trajet_count);
        let variationsFiltered = data.data.variations.filter(point => point.publication_count || point.trajet_count);
        this.allMarkers = [...pointsFiltered, ...variationsFiltered];
        if (this.allMarkers.length) {
          setTimeout(() => {
            this.initMap();
          }, 100);
        } else {
          setTimeout(() => {
            this.map = new mapboxgl.Map({
              container: 'map-global',
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [2.3522219, 48.856614],
              zoom: 3,
              accessToken: this.mapboxPK
            });
            this.dismissLoading();
            this.watchPosition();
          }, 100);
        }
      },
      err => {
        console.log(err);
      }
    );
    this.mapService.getNewPoint().subscribe((data) => {
      this.changePoint(data);      
    });
    this.mapService.getNewBounds().subscribe((data) => {
      if (data == 'all') {
        this.fitBounds();
        if (this.currentMarkers.length) {
          this.currentMarkers.forEach(marker => {
            marker.remove();
          });
        }
        if (this.hiddenMarkers.length) {
          this.hiddenMarkers.forEach(marker => {
            marker.addTo(this.map);
          });
        }
        const style = document.createElement('style');
        style.innerHTML = `app-map-desktop .marker-not-current {opacity: 1;}`;
        document.body.appendChild(style);
      } else if (data.length) {
        if (!this.markersMap.length) {
          setTimeout(() => {
            this.mapService.showNewBounds(data);
          }, 200);
        } else {
          let activeBounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
          data.forEach(el => {
            activeBounds.extend([el.lon, el.lat]);
            this.markersMap.forEach(m => {
              let lng = m.getLngLat().lng;
              let lat = m.getLngLat().lat;
              if (el.lon == lng && el.lat == lat) {
                this.hiddenMarkers.push(m);
                m.remove();
                this.addMarker(el, true);
              }
            });
          });
          this.map.fitBounds(activeBounds, {padding: 130, maxZoom: 15});
          const style = document.createElement('style');
          style.innerHTML = `app-map-desktop .marker-not-current {opacity: 0.2;}`;
          document.body.appendChild(style);
        }
      }
    });
  }

  async ionViewWillEnter() {
    this.networkStatus = await Network.getStatus();
    if (this.networkStatus.connected) {
      await this.dismissLoading();
      return;
    }
  }

  getCategories() {
    let lang = this.translateService.getBrowserLang();
    if (lang.startsWith('fr')) lang = 'fr_FR';
    if (lang.startsWith('en')) lang = 'en_GB';
    if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
    this.apiService.getCategories(lang).subscribe(
      data => {
        let categories = data.data;
        if (categories.length) {
          categories.forEach(cat => {
            let id = cat.id;
            let img = cat.medias[0].url;
            let title = cat.name;
            let slug = slugify(cat.name);
            this.allCategories.push({id, img, slug, title});
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  } 

  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map-global',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2.3522219, 48.856614],
      zoom: 3,
      accessToken: this.mapboxPK
    });

    this.watchPosition();
    this.dismissLoading();

    this.allMarkers.forEach(marker => {
      this.addMarker(marker);
    });

    Plugins.Geolocation.getCurrentPosition().then((position) => {
      this.boundsUserNearestPoint.extend([position.coords.longitude, position.coords.latitude]);
      let nearestPoint = this.nearestPoint(position.coords.latitude, position.coords.longitude);
      this.boundsUserNearestPoint.extend([nearestPoint.lon, nearestPoint.lat]);
      this.map.fitBounds(this.boundsUserNearestPoint, {maxZoom: 15, padding: 50, duration: 500});
    }).catch((error) => {
      this.map.fitBounds(this.bounds, {maxZoom: 15, padding: 50, duration: 0});
    });

  }

  addMarker(marker, current?: boolean) {
    var el = document.createElement('div');
    (current) ? el.className = 'marker' : el.className = 'marker-not-current';
    if (marker.catpoint_id && this.allCategories.length) {
      let category = this.allCategories.find(x => x.id === marker.catpoint_id);
      el.style.backgroundImage = "url('" + category.img + "')";
      el.classList.add(category.slug);
    }
    
    if (!marker.description) marker.description = '';

    let markerEl = new mapboxgl.Marker(el)
      .setLngLat([marker.lon, marker.lat])
      .addTo(this.map);

    markerEl.getElement().addEventListener('click', () => {
      this.showPoint(marker);
    });

    (current) ? this.currentMarkers.push(markerEl) : this.markersMap.push(markerEl);
    if (!current) this.bounds.extend([marker.lon, marker.lat]);
  }

  watchPosition() {
    var el = document.createElement('div');
    el.className = 'mapboxgl-user-location-dot mapboxgl-marker mapboxgl-marker-anchor-center';
    let marker = new mapboxgl.Marker(el).setLngLat([33, 33]).addTo(this.map);
    try {
      this.watchId = Plugins.Geolocation.watchPosition({enableHighAccuracy: true}, (position) => {
        this.zone.run(() => {
          if (position) {
            this.watchCoordinate = [
              position.coords.longitude,
              position.coords.latitude,
            ];
            marker.setLngLat(this.watchCoordinate);
          }
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  showPoint(point) {
    this.mapService.showPoint(point);
    if (point.point_id) {
      this.statsService.addStatAction(this.deviceID, 'clic_map', {point_id: point.point_id, variation_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.statsService.addStatAction(this.deviceID, 'clic_map', {point_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  flyToUser() {
    if (this.watchCoordinate) {
      this.map.flyTo({
        center: this.watchCoordinate,
        zoom: 14,
        speed: 1
      });
    }
  }

  fitBounds() {
    this.map.fitBounds(this.bounds, {padding: 130});
  }

  ionViewWillLeave() {
    this.mapService.showPoint('close');
    if (this.watchId != null) {
      Plugins.Geolocation.clearWatch({ id: this.watchId });
    }
  }

  changePoint(point) {
    this.mapService.showPoint('close');
    this.showPoint(point);
  }

  nearestPoint(latitude, longitude) {
    let minDif = 99999999;
    let closest;
    for (let index = 0; index < this.allMarkers.length; ++index) {
      let dif = this.pythagorasEquirectangular(latitude, longitude, Number(this.allMarkers[index].lat), Number(this.allMarkers[index].lon));
      if (dif < minDif) {
        closest = index;
        minDif = dif;
      }
    }
    return this.allMarkers[closest];
  }

  pythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = this.deg2Rad(lat1);
    lat2 = this.deg2Rad(lat2);
    lon1 = this.deg2Rad(lon1);
    lon2 = this.deg2Rad(lon2);
    var R = 6371; // km
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    var y = (lat2 - lat1);
    var d = Math.sqrt(x * x + y * y) * R;
    return d;
  }

  deg2Rad(deg) {
    return deg * Math.PI / 180;
  }

  toggleSidebar() {
    let sidebar = document.getElementById('sidebar-filter');
    if (sidebar.classList.contains('active')) {
      sidebar.classList.remove('active');
    } else {
      sidebar.classList.add('active');
    }
  }

}

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}