import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';
import { CachingService } from './caching.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private cachingService: CachingService, private http: HttpClient) {}


  listPublications(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/home`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listPublicationsLight(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/home_light`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/home_light`, resData);
      })
    );
  }

  getAllPointsAndVariations(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/maps`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPoint(stats_devices_id: string, point_id: string, variation_id?: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('point_id', point_id);
    if (variation_id) form.set('variation_id', variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/points`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPublication(stats_devices_id: string, article_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('article_id', article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/publication/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/publication/get/${article_id}`, resData);
      })
    );
  }

  getPublications(ids: any, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('ids', ids);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/publications/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getRoute(stats_devices_id: string, route_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('route_id', route_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/route/get/${route_id}`, resData);
      })
    );
  }

  getRoutes(ids: any, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('ids', ids);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/routes/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listRoute(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listRoutesLight(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/list_light`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/route/list_light`, resData, 31536000);
      })
    );
  }

  getMapboxRoute(profile, coordinates, accessToken) {
    let url = 'https://api.mapbox.com/directions/v5/mapbox/' + profile + '/' + coordinates + '?alternatives=true&geometries=geojson&overview=full&access_token=' + accessToken;
    return this.http.get<any>(
      url,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPointPublications(options: {point_id?: string, variation_id?: string}) {
    const form: FormData = new FormData();

    if (options.point_id) form.set('point_id', options.point_id);
    if (options.variation_id) form.set('variation_id', options.variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/point_publications`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPointRoutes(options: {point_id?: string, variation_id?: string}) {
    const form: FormData = new FormData();

    if (options.point_id) form.set('point_id', options.point_id);
    if (options.variation_id) form.set('variation_id', options.variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/point_routes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getConfig(options?: {apptitle?: string, lang?: string}) {
    const form: FormData = new FormData();

    if (options) {
      if (options.apptitle) form.set('apptitle', options.apptitle);
      if (options.lang) form.set('lang', options.lang);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}config/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}config/list`, resData);
      })
    );
  }

  getLangElements() {
    const form: FormData = new FormData();
    
    return this.http.post<any>(
      `${environment.baseApiUrl}lang_element/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}lang_element/list`, resData);
      })
    );

  }

  getCategories(lang) {
    const form: FormData = new FormData();
    form.set('lang', lang);

    return this.http.post<any>(
      `${environment.baseApiUrl}catpoint/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  searchArticle(search: string, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('string', search);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/articles_search`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

}
