import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Platform } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
const { Browser } = Plugins;
const { Storage } = Plugins;

@Component({
  selector: 'app-toaster-share',
  templateUrl: './toaster-share.component.html',
  styleUrls: ['./toaster-share.component.scss'],
  providers: [TranslatePipe]
})
export class ToasterShareComponent implements OnInit {

  @Input() shareData: any;

  constructor(private commonService: CommonService, private platform: Platform, private emailComposer: EmailComposer) { }

  ngOnInit() {}

  onClose() {
    this.commonService.publishShares(
      'close'
    );
  }

  share(destination) {
    let url = environment.baseShareUrl + this.shareData.id + '/' + this.shareData.title;
    if (destination == 'email') {
      if(this.platform.is('cordova')) {
        let email = {
          to: '',
          subject: 'Publication géolocalisée',
          body: url,
          isHtml: true
        }
        this.emailComposer.open(email);
      } else {
        window.open("mailto:?subject=Publication&body=" + url);
      }
    } else {
      switch (destination) {
        case 'facebook':
          url = "https://www.facebook.com/sharer/sharer.php?u=" + url;
          break;
        case 'twitter':
          url = "https://www.twitter.com/share?url=" + url;
          break;
        case 'linkedin':
          url = "https://www.linkedin.com/sharing/share-offsite/?url=" + url;
          break;  
        default:
          break;
      }
      this.openInAppURL(url);
    }
  }

  async openInAppURL(url) {
    const keyColor = await Storage.get({ key: 'navColor' });
    let navColor = keyColor.value;
    if (!navColor) navColor = '#CCC';
    if (this.platform.is('ipad')) {
      await Browser.open({ url: url, presentationStyle: 'fullscreen', toolbarColor: navColor });
    } else {
      await Browser.open({ url: url, presentationStyle: 'popover', toolbarColor: navColor });
    }
  }

}
